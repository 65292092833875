import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faTerminal } from '@fortawesome/free-solid-svg-icons';
import Canvas from './components/Canvas';
import Controls from './components/Controls';
import Terminal from './components/Terminal';
import LinkSection from './components/LinkSection';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

function App() {
  const [background, setBackground] = useState('/assets/backgrounds/background1.png');
  const [avatar, setAvatar] = useState('');
  const [accessories, setAccessories] = useState([]); // Multiple accessories
  const [customText, setCustomText] = useState('Your Text Here');
  const [showTextElement, setShowTextElement] = useState(false);
  const [customImage, setCustomImage] = useState(null);
  const [stageRef, setStageRef] = useState(null);

  const handleDownload = () => {
    if (stageRef) {
      const transformers = stageRef.find('Transformer');
      transformers.forEach((transformer) => transformer.visible(false));

      const dataURL = stageRef.toDataURL({
        pixelRatio: 2,
        mimeType: 'image/png',
        quality: 1,
      });

      const link = document.createElement('a');
      link.download = 'major-builder.png';
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      transformers.forEach((transformer) => transformer.visible(true));
      stageRef.draw();
    } else {
      console.error('Stage reference not set');
    }
  };

  return (
    <div className="App">
      <header className="app-header">
        <div className="header-left">
          <h1>
            <FontAwesomeIcon icon={faGlobe} className="icon" /> MAJOR_LAB &gt; SUCCESSFULLY INITIALIZED
          </h1>
        </div>
        <div className="header-right">
          <span className="dev-info">
            DEV BY <a href="https://x.com/nifty_sol" target="blank">@NIFTY_SOL</a>
          </span>
          <span className="version">VERSION 1.0.0</span>
        </div>
      </header>

      <div className="main-layout">
        <div className="canvas-section">
          <div className="canvas-header">
            <div className="canvas-title">MAJOR_BUILDER</div>
            <div className="canvas-size">512x512</div>
          </div>
          <Canvas
            background={background}
            avatar={avatar}
            accessories={accessories} // Updated for multiple accessories
            setAccessories={setAccessories} // Pass the setAccessories function
            customText={customText}
            setCustomText={setCustomText} // Pass the setCustomText function for text deletion
            showTextElement={showTextElement}
            setShowTextElement={setShowTextElement} // Pass setShowTextElement for hiding text
            customImage={customImage}
            setCustomImage={setCustomImage} // Pass the setCustomImage function for image deletion
            setStageRef={setStageRef}
          />
        </div>
        <div className="controls-section">
          <Controls
            setBackground={setBackground}
            setAvatar={setAvatar}
            setAccessories={setAccessories} // Updated for multiple accessories
            setCustomText={setCustomText}
            setShowTextElement={setShowTextElement}
            setCustomImage={setCustomImage}
          />
        </div>
      </div>

      <div className="terminal-sep">
        <FontAwesomeIcon icon={faTerminal} /> Terminal
      </div>

      <footer className="footer">
        <Terminal />
        <LinkSection />
        <button onClick={handleDownload} className="download-button">
          Download Image
        </button>
      </footer>
      <Analytics />

    </div>
  );
}

export default App;
