import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Function to detect Brave and redirect
async function detectBraveAndRedirect() {
  const isBrave = navigator.brave && (await navigator.brave.isBrave());
  if (isBrave) {
    window.location.href = "https://brave.majorfrog.meme";
  } else {
    // Render the app only if the user is not Brave
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
}

// Call the function
detectBraveAndRedirect();

// For performance monitoring
reportWebVitals();
